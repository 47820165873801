import { TRANSACTION_FRAGMENT } from 'src/fragments/TransactionFragments';
import { gql } from 'urql';

export const GET_TRANSACTIONS = gql`
  query GetTransactions {
    transaction_connection {
      edges {
        node {
          ...Transaction
        }
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
