import { gql } from 'urql';

export const TRANSACTION_FRAGMENT = gql`
  fragment Transaction on transaction {
    id
    amount
    category
    created_at
    creator_id
    date
    description
    type
    updated_at
  }
`;
