import { GetTransactionsQuery } from '@generated/graphql';
import { useQuery } from 'urql';

import { GET_TRANSACTIONS } from '../Queries/TransactionQuery';

export default function Homepage() {
  const [{ data, fetching, error }] = useQuery<GetTransactionsQuery>({
    query: GET_TRANSACTIONS
  });

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  return (
    <ul>
      {data?.transaction_connection.edges.map((transaction) => {
        return <li key={transaction.node.id}>{transaction.node.amount}</li>;
      })}
    </ul>
  );
}
